import "@popperjs/core";
import "bootstrap";
import React from "react";
import AOS from "aos";
import { SWRConfig } from "swr";

const onClientEntry = () => {
    AOS.init({
        duration: 1000,
        delay: 50,
    });
};

export const wrapRootElement = ({ element }) => (
    <SWRConfig
        value={{
            fetcher: (path, init) =>
                fetch(`${process.env.GATSBY_API_URL}${path}`, init).then(
                    (res) => res.json()
                ),
        }}
    >
        {element}
    </SWRConfig>
);

export { onClientEntry };
