exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-aiacademy-js": () => import("./../../../src/pages/products/aiacademy.js" /* webpackChunkName: "component---src-pages-products-aiacademy-js" */),
  "component---src-pages-products-convert-ppt-js": () => import("./../../../src/pages/products/convert-ppt.js" /* webpackChunkName: "component---src-pages-products-convert-ppt-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-large-language-models-js": () => import("./../../../src/pages/products/large-language-models.js" /* webpackChunkName: "component---src-pages-products-large-language-models-js" */),
  "component---src-pages-products-machine-translation-js": () => import("./../../../src/pages/products/machine-translation.js" /* webpackChunkName: "component---src-pages-products-machine-translation-js" */),
  "component---src-pages-products-msearch-cz-js": () => import("./../../../src/pages/products/msearch/cz.js" /* webpackChunkName: "component---src-pages-products-msearch-cz-js" */),
  "component---src-pages-products-msearch-en-js": () => import("./../../../src/pages/products/msearch/en.js" /* webpackChunkName: "component---src-pages-products-msearch-en-js" */),
  "component---src-pages-products-msearch-js": () => import("./../../../src/pages/products/msearch.js" /* webpackChunkName: "component---src-pages-products-msearch-js" */)
}

